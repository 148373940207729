import React, { useState, useEffect } from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Containers from "../../components/AccountContent/Containers"
import AccountNavigation from "../../components/AccountContent/AccountNavigation"
import axios from "axios"
import swell, { account } from "swell-js"
import async from "async"
import { useUserContext } from "../../context/UserContext"
import { useInsertionEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ELink from "src/components/Core/ExternalLink"

function sendCollectGtag() {
  let gtag_data = {
    event_category: "engagement",
    event_label: "collect"
  }
  // if (typeof window.gtag !== 'undefined') {
  //   window.gtag('event', 'click', gtag_data)
  //   //todo: add GA4 event
  // }
}
const ContainersPage = ({ location }) => {
 
 //console.log(useUserContext())
 const {user} = useUserContext();




 
 
 

  const path = location.pathname
  
 
 
  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Containers Page"
        description="In Good Company Containers Page"
        fullTitle="Containers Page"
        path={path}
      />
      <AccountNavigation path={path}>
        <Containers />
       
          {/* <Link to="/collect" className="account" onClick={sendCollectGtag}>
                    Schedule A Collection
              </Link> */}
             
      </AccountNavigation>
    </Layout>
  )
}

export default ContainersPage
