import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { getTypeParameterOwner } from "typescript"
import { useUserContext } from "../../context/UserContext"
import { getUser } from "../../services/user/user"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import { Link } from "gatsby"
import breakpoints from "src/styles/breakpoints"
import { black } from "picocolors"
import { bold } from "kleur"
import ELink from "../Core/ExternalLink"
import { Row, Col } from "styled-bootstrap-grid"
import { rangeRight } from "lodash"

export default function Component() {
  let loopData = ""
  const [loops, setLoops] = useState([])
  const [deliveries, setDeliveries] = useState([])
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const { user, setUser } = useUserContext()

  useEffect(() => {
    if (loops !== null && loops.length > 0) {
      setIsLoading(false)
    } else if (user !== null) {
      getLoops(user.id)
      setIsLoading(true)
    } else {
      getUser()
        .then((res) => {
          setUser(res.results).then((response) => {
            getLoops(response.id)
          })
          setIsLoading(true)
        })
        .catch((e) => {
          console.error(e)
          setIsError(true)
        })
    }
  })

  const getLoops = (accountId) => {
    let response
    try {
      const url = "/.netlify/functions/getLoops"

      const loopData = {
        accountId,
      }

      response = axios.post(url, loopData).then((result) => {
        if (result.data == "no loops found") {
          setIsError(true)
        } else {
          //   console.log("result.data" + JSON.stringify(result.data))
          //   console.log()
          setDeliveries(result.data.deliveryData?.results)
          setLoops(result.data.loopData.results)
          setOrders(result.data.orderData?.results)
        }
      })

      if (response?.status === 200) {
      }
    } catch (error) {
      console.error(error)
    }
    try {
      return response.data
    } catch (e) {
      return e
    }
  }

  if (isError) {
    return (
      <>
        <h3>Containers</h3>
        <p>No Containers Loaded</p>
      </>
    )
  }
  if (isLoading) {
    return (
      <>
      <div style={{paddingLeft: "2.5rem"}}>
        <h3>Containers</h3>
        <p>Loading...</p>
        </div>
      </>
    )
  }

  let open_loops = []
  let closed_loops = []
  let charged_loops = []
  let other_loops = []

  let next_due_date
  try {
    next_due_date = new Date(
      loops
        .filter((x) => x.status === "open")
        .sort((x, y) => {
          if (x.current_due_date && !y.current_due_date) {
            return -1
          } else if (!x.current_due_date && y.current_due_date) {
            return 1
          } else if (!x.current_due_date && !y.current_due_date) {
            return 0
          } else if (
            x.current_due_date.due_date < y.current_due_date.due_date
          ) {
            return -1
          } else {
            return 1
          }
        })[0].current_due_date.due_date
    ).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    })
  } catch (e) {
    next_due_date = "Due ASAP"
  }

  let open_loop_due_date_sorter = {}

  for (const loop of loops) {
    if (loop.status === "open") {
      if (
        !loop.current_due_date ||
        (loop.current_due_date && !loop.current_due_date.due_date)
      ) {
        loop.current_due_date = { due_date: "Due ASAP" }
      }

      if (
        open_loop_due_date_sorter[loop.current_due_date.due_date.split("T")[0]]
      ) {
        open_loop_due_date_sorter[loop.current_due_date.due_date.split("T")[0]]
          .count++
        let date_exists = false
        let i = 0
        let dateIndex = 0
        for (const opened_date of open_loop_due_date_sorter[
          loop.current_due_date.due_date.split("T")[0]
        ].opened_dates) {
          let date1 = new Date(loop.open_date)
          let date2 = new Date(opened_date.date)

          if (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
          ) {
            date_exists = true
            dateIndex = i
          }
          i++
        }
        if (!date_exists) {
          open_loop_due_date_sorter[
            loop.current_due_date.due_date.split("T")[0]
          ].opened_dates.push({ date: loop.open_date, count: 1 })
        } else {
          open_loop_due_date_sorter[
            loop.current_due_date.due_date.split("T")[0]
          ].count = 1
          open_loop_due_date_sorter[
            loop.current_due_date.due_date.split("T")[0]
          ].opened_dates[dateIndex].count++
        }
        continue
      } else {
        open_loop_due_date_sorter[
          loop.current_due_date.due_date.split("T")[0]
        ] = { opened_dates: [{ date: loop.open_date, count: 1 }] }

        continue
      }
    }
  }

  for (const loop of loops.filter(
    (x) => x.status !== "open" && x.status !== "closed"
  )) {
    let thisLoop = ""
    let bgColor = ""
    switch (loop.status) {
      case "open":
        bgColor = "green"
        break
      case "closed":
        bgColor = "grey"
        break
      case "charged":
        bgColor = "red"
        break
      default:
        bgColor = "black"
        break
    }
    thisLoop +=
      "<div style='background-color:" +
      bgColor +
      "; color: white; border-radius: 5px; width:500px'>"
    thisLoop += "<ul style='margin: 8px' >"
    thisLoop += "<li>"
    thisLoop += "Status: " + loop.status
    thisLoop += "</li>"
    let openDate = new Date(loop.open_date)
    thisLoop += "<li>"
    thisLoop +=
      "Opened On: " +
      (openDate.getMonth() + 1) +
      "/" +
      openDate.getDate() +
      "/" +
      openDate.getFullYear()
    thisLoop += "</li>"
    thisLoop += "<li>"
    let dueDate = loop.current_due_date
      ? loop.current_due_date.due_date
        ? new Date(loop.current_due_date.due_date)
        : "please return asap"
      : "please return asap"
    if (dueDate instanceof Date && !isNaN(dueDate.valueOf())) {
      thisLoop +=
        "Due Date: " +
        (dueDate.getMonth() + 1) +
        "/" +
        dueDate.getDate() +
        "/" +
        dueDate.getFullYear()
    } else {
      thisLoop += "Due Date: " + dueDate
    }

    thisLoop += "</li>"
    thisLoop += "</ul>"
    thisLoop += "</div>"
    thisLoop += "<br>"

    switch (loop.status) {
      case "open":
        open_loops.push(thisLoop)
        break
      case "closed":
        closed_loops.push(thisLoop)
        break
      case "charged":
        charged_loops.push(thisLoop)
        break
      default:
        other_loops.push(thisLoop)
        break
    }
  }
  const orderedDates = Object.keys(open_loop_due_date_sorter)
    .sort()
    .reduce((obj, key) => {
      obj[key] = open_loop_due_date_sorter[key]
      return obj
    }, {})
  Object.entries(orderedDates).forEach(([key, value]) => {
    let due_date
    if (key === "Due ASAP") {
      due_date = "Due ASAP"
    } else {
      due_date = new Date(key.replace(/-/g, "/")).toLocaleDateString("en-us", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    }
    let data = `
        <div style='background-color:green; color: white; border-radius: 5px; width:500px'>
        <ul style='margin: 8px' >`

    if (open_loops.length > 0) {
      data += `<li>Due Date: ` + due_date + `</li>`
    }

    data +=
      `<li>Containers Due: ` +
      loops.filter(
        (x) =>
          x.status === "open" &&
          x.current_due_date &&
          x.current_due_date.due_date &&
          x.current_due_date.due_date.split("T")[0] === key
      ).length +
      `</li>`

    for (const opened_date of value.opened_dates) {
      data +=
        `<li> • ` +
        opened_date.count +
        ` Container(s) delivered On ` +
        new Date(opened_date.date).toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        }) +
        `</li>`
    }
    data += `</ul>
        </div>`
    open_loops.push(data)
  })
  loopData += "<div >"
  //   loopData += user.name + " Meal Containers Summary<br>";
  //   loopData +=  "Total Currently Outstanding: ("+loops.filter(x=>x.status=="open").length+")";
  //   loopData += "<div>Containers Returned This Year: (" + loops.filter(x=>x.status == "closed").length+")</div>";
  //   loopData += "<br>";
  //   loopData+= "<h4>Next Due Date: "+ next_due_date + "</h4>" +
  //   open_loops[0];
  loopData += "<br>"
  loopData += "<details><summary>See Future Due Dates</summary>"

  loopData += open_loops.slice(1).join("")
  loopData += "</details>"
  //<details><summary>Containers Returned ("+closed_loops.length+")</summary>"+ closed_loops.join('') + "</details><details><summary>Containers Charged ("+charged_loops.length+")</summary>" + charged_loops.join('')+ "</details><details><summary> All Other Loops("+other_loops.length+")</summary>" + other_loops.join('')+"</details>";

  //loopData = loopData.replace(',', ' ');
  loopData += "<br>"
  loopData +=
    "<details><summary>Containers Charged (" +
    charged_loops.length +
    ")</summary>" +
    charged_loops.join("") +
    "</details>"
  loopData += "</div>"
  loopData += "<br>"
  //   console.log("check it out: " + JSON.stringify(open_loop_due_date_sorter))
  //   console.log(
  //     "check it out: " +
  //       JSON.stringify(Object.keys(open_loop_due_date_sorter).sort()[0])
  //   )

  const due_on_next_due_date = loops.filter(
    (x) =>
      x.status === "open" &&
      next_due_date &&
      x.current_due_date.due_date &&
      x.current_due_date.due_date.split("T")[0] ===
        Object.keys(open_loop_due_date_sorter).sort()[0]
  ).length
  //   console.log(loops)
  //   console.log(due_on_next_due_date)
  //   console.log(Object.keys(open_loop_due_date_sorter).sort()[0])
  let total_delivered = 0
  let total_returned = 0
  let total_remaining = 0
  let totalContainersDelivered = 0
  let totalContainersReturned = 0
  let totalContainersCharged = 0
  let totalCurrentBalance = 0

  let containerChargeHistory = [];
  //let relevenantLoops = loops.filter(x=>x.status === "charged" || x.credit_amount > 0 || (x.closed_type === "manual" && x.status === "closed"))
  let chargedLoops = loops.filter(x=>x.status === "charged" )
  let creditedLoops =  loops.filter(x=>x.credit_amount > 0  )
  let forgivenLoops = loops.filter(x=> x.closed_type === "manual" && x.status === "closed")
  
  let dateTimes = {};

  for (const chargedLoop of chargedLoops) {
    if (dateTimes[chargedLoop.charge_date.split('T')[0]+"charge"]) {
      dateTimes[chargedLoop.charge_date.split('T')[0]+"charge"].containers_closed++
      dateTimes[chargedLoop.charge_date.split('T')[0]+"charge"].amount_charged += chargedLoop.actual_charge_amount
    } else {
      let date = (new Date(chargedLoop.charge_date)).toLocaleString().split(",")[0];
      dateTimes[chargedLoop.charge_date.split('T')[0]+"charge"] = {
        action: "Charged",
        date,
        containers_closed: 1,
        amount_charged: chargedLoop.actual_charge_amount
      }
    }
  }

  for (const creditedLoop of creditedLoops) {
    if (dateTimes[creditedLoop.credit_date.split('T')[0]+"credit"]) {
      dateTimes[creditedLoop.credit_date.split('T')[0]+"credit"].containers_closed++;
      dateTimes[creditedLoop.credit_date.split('T')[0]+"credit"].amount_charged += creditedLoop.credit_amount
    } else {
      let date = (new Date(creditedLoop.credit_date)).toLocaleString().split(",")[0];
      let amount_charged = creditedLoop.credit_amount
      dateTimes[creditedLoop.credit_date.split('T')[0]+"credit"] = {
        action: "Credit Applied",
        date,
        containers_closed: 1,
        amount_charged
      }
    }
  }

  for (const forgivenLoop of forgivenLoops) {
    if (dateTimes[forgivenLoop.closed_date.split('T')[0]+"forgive"]) {
      dateTimes[forgivenLoop.closed_date.split('T')[0]+"forgive"].containers_closed++;
      
    } else {
      let date = (new Date(forgivenLoop.closed_date)).toLocaleString().split(",")[0];
      // let date = (new Date(forgivenLoop.closed_date.split)).toLocaleString().split(",")[0];
      let amount_charged = 0
      dateTimes[forgivenLoop.closed_date.split('T')[0]+"forgive"] = {
        action: "Closed By Admin",
        date,
        containers_closed: 1,
        amount_charged: 0
      }
    }
  }


  let chargeEvents = []
  for (const entry of Object.keys(dateTimes))
  {
     chargeEvents.push(dateTimes[entry])
  }

  console.log(chargeEvents)

  let loopsReturned = loops.filter((x) => x.status === "closed" && x.closed_type === "delivery").length;
    
  return (
    <Container>
      <Row style={{marginBottom: "50px"}}>
        <Col md={8} lg={6}>
          <Header>Container Tracking</Header>
          <Stats> <em style={{fontWeight: "bold"}}>Next Due Date: {next_due_date}</em></Stats>
          <Stats>
          <em style={{fontWeight: "bold"}}> # of Containers Due on This Date: {due_on_next_due_date}</em>{" "}
          </Stats>
          <Stats>
            Total # of Containers On Loan:{" "}
            {loops.filter((x) => x.status === "open").length}
          </Stats>
          <SubHeading style={{  marginTop: "20px"}}>Have Containers to Return?</SubHeading>
          <InternalLinkButton to={"/shop"}>Place An Order</InternalLinkButton>
          <InternalLinkButton href={"https://eatigc.typeform.com/to/XKVe9fDy"}>
            Schedule A Pick-Up
          </InternalLinkButton>
        </Col>
        <Col md={6}>
          {  loopsReturned > 0 ? 
          <Rectangle>
            <Header
              style={{ color: "white", padding: "10px", marginBottom: "10px" }}
            >
              🥳 You have returned{" "}
              {loopsReturned} containers over
              all time!
            </Header>
            <p style={{ color: "white", padding: "10px" }}>
            On average, each IGC container replaces 3-4 pieces of single use packaging that would otherwise end up in landfills, incinerators, or our oceans. Thanks for being part of the reuse revolution! {" "}
            </p>
          </Rectangle>
          : ""
          }
    
        </Col>
      </Row>

      <DetailsDiv>
        <details>
          <summary><SubHeading style={{display: "inline"}}>How To Return Your Containers</SubHeading></summary>
          <p>Returning your containers is as easy as... </p>
          <p>
            1. <InternalLink to={"/shop"}>Place a new order now! </InternalLink>{" "}
            You can return your used containers at the time of your next
            delivery.
          </p>

          <br />

          <p style={{ fontWeight: "bold" }}>OR</p>

          <br />

          <p>
            2.{" "}
            <ExternalLink href={"https://eatigc.typeform.com/to/XKVe9fDy"}>
              Schedule a free at home-pickup.
            </ExternalLink>
            We will send a driver to pick-up your containers sometime in the
            next few weeks.
          </p>

          <br />

          <p>
            Please note that once you complete either action, no past due fees
            will be charged until after your delivery or pick-up is complete to
            ensure you are able to return your containers.
          </p>

          <br />

          <p>
            If at any time you are charged for unreturned containers and then
            decide to return your container at a future date, a $15 per
            container credit is automatically applied to your account.
          </p>
        </details>
        <details>
          <summary>
          <SubHeading style={{display: "inline"}}>Container Usage History</SubHeading>
          </summary>

      
        <WhiteRectangle>
        <Header>Order Tracking</Header>
        <TableDiv>
        <Table>
          <Tr>
          <Th>Order Number</Th>
            <Th>Order Date</Th>
            <Th>Container Return Date</Th>
            <Th>Containers Delivered</Th>
            <Th>Containers Returned</Th> 
            <Th>Containers Closed</Th>
            <Th>Still On Loan</Th>
          </Tr>

          {orders &&
            orders.map((order, index) => {
              let delivery = deliveries.filter(
                (x) =>
                  x.delivery_number === order.number && x.status === "success"
              )[0]
              let delivery_date
              if (delivery)
              {
                delivery_date = delivery.end_time ?? delivery.optimo_assigned_date
                delivery_date = (new Date(delivery_date)).toLocaleString().split(",")[0]
                
              }
               
              else {
                delivery_date = "N/A"
              }

              let orderLoops
              let due_date
              let containers_delivered
              let containers_returned
              let orderReturnedLoops
              let loopsClosed
              let openOrderLoops
              let current_balance
              let containers_charged
            
              try {
                orderLoops = loops.filter((x) => x.open_order_id === order.id)
                orderReturnedLoops = loops.filter(
                  (x) => x.open_order_id === order.id && x.status === "closed" && x.closed_type === "delivery"
                )
                if ( orderLoops.length > 0 && orderLoops[0].current_due_date) {
                  due_date = (new Date(orderLoops[0].current_due_date?.due_date)).toLocaleString().split(',')[0]
                }
                else {
                 return
                }
              
                containers_delivered = orderLoops.length
                totalContainersDelivered += containers_delivered
                containers_returned = orderReturnedLoops.length
                loopsClosed = loops.filter((x) => x.open_order_id === order.id && (x.status === "charged" || (x.status === "closed" && x.closed_type === "manual" ))  )
                openOrderLoops = orderLoops.filter(x => x.status === "open")
                current_balance = openOrderLoops.length
                containers_charged = loopsClosed.length
                totalContainersReturned += containers_returned
                totalContainersCharged += containers_charged
                totalCurrentBalance += current_balance
              } catch (e){
                due_date = "N/A"
                
                console.log(e)
                return(<></>)
              }

              {
                /* console.log(JSON.stringify(delivery_date)) */
              }
              return (
                <Tr>
                <Td>{order.number}</Td>
                  <Td>{delivery_date}</Td>
                  <Td>{due_date}</Td>
                  <Td>{containers_delivered}</Td>
                  <Td>{containers_returned}</Td>
                  <Td>{containers_charged}</Td>
                  <Td>{current_balance}</Td>
                </Tr>
              )
            })}
            <Tr>
              <Td style={{fontWeight: "bold"}}>
                Lifetime Totals:
              </Td>
              <Td  style={{fontWeight: "bold"}}></Td>
              <Td  style={{fontWeight: "bold"}}></Td>
              <Td  style={{fontWeight: "bold"}}>{totalContainersDelivered}</Td>
              <Td  style={{fontWeight: "bold"}}>{totalContainersReturned}</Td>
              <Td  style={{fontWeight: "bold"}}>{totalContainersCharged}</Td>
              <Td  style={{fontWeight: "bold"}}>{totalCurrentBalance}</Td>



            </Tr>
        </Table>
        </TableDiv>
        </WhiteRectangle>
      
        <WhiteRectangle>
        <Header>Delivery & Pick-Up History</Header>
        <TableDiv>
        <Table>
          <Tr>
            {/* <Th>Order Number</Th>
            <Th>Date</Th> */}
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>Containers Delivered</Th>
            <Th>Containers Returned</Th>
            {/* <Th># of Remaining Containers From This Delivery</Th> */}
          </Tr>

          {deliveries &&
            deliveries.map((delivery, index) => {
              const deliveryDate = new Date(delivery.date_created)
                .toLocaleString()
                .split(",")[0]
              const remaining = loops.filter(
                (x) => x.open_delivery_id === delivery.id && x.status === "open"
              ).length
              const returned =
                delivery.form && delivery.form.containers_returned
                  ? delivery.form.containers_returned
                  : 0
              total_delivered += delivery.container_count ?? 0
              total_returned += returned
              total_remaining += remaining

              return (
                <Tr>
                  {/* <Td>{delivery.delivery_number}</Td> */}
                  <Td>{deliveryDate}</Td>
                  <Td>
                    {delivery.type === "D"
                      ? "Delivery"
                      : delivery.type === "P"
                      ? "Pickup"
                      : ""}
                  </Td>
                  <Td>{delivery.container_count ?? 0}</Td>
                  <Td>{returned}</Td>
                  {/* <Td>
                     {remaining}
                  </Td> */}
                </Tr>
              )
            })}
          <Tr>
            <Td style={{ fontWeight: "bold" }}>Lifetime Totals:</Td>
           
            <Td></Td>
            <Td style={{ fontWeight: "bold" }}>{total_delivered}</Td>
            <Td style={{ fontWeight: "bold" }}>{total_returned}</Td>
            {/* <Td style={{fontWeight: "bold"}}>{total_remaining}</Td> */}
          </Tr>
        </Table>
        </TableDiv>


        </WhiteRectangle>
   
     
        <WhiteRectangle>
        <Header>Container Charge History</Header>
        <TableDiv>
        <Table>
          <Tr>
            <Th>Date</Th>
            <Th>Action</Th>
            <Th>Containers Closed</Th>
            <Th>Amount Charged</Th>
          </Tr>
            
   
            {chargeEvents && chargeEvents.map((event, index)=>{
              return (<Tr>
              <Td>{event.date}</Td>
              <Td>{event.action}</Td>
              <Td>{event.containers_closed}</Td>
              <Td style={{color: "neongreen"}}>{event.action === "Credit Applied" ? "($" : "$"}{event.amount_charged.toFixed(2)}{event.action === "Credit Applied" ? ")" : ""}</Td>
              </Tr>)
            })
            }

       
        </Table>
        </TableDiv>
        </WhiteRectangle>
        </details>
      </DetailsDiv>
    </Container>
  )
}


const Container = styled.div`
  padding-left: 2.5rem;
`

const DetailsDiv = styled.div`
  details > summary {
    list-style-type: "+ ";
    ${'' /* text-transform: uppercase; */}
    font-weight: bold;
    font-size: 30px;
  }

  details[open] > summary {
    list-style-type: "+ ";
  }

  details {
    font-weight: 400;
  }

  details[open] > summary {
    margin-bottom: 0.5rem;
  }
`

const Header = styled.h4``

const SubHeading = styled.h4`
  font-weight: 500;

`

const Stats = styled.p`
font-size: calc(.4vw + 12px);`

const InternalLinkButton = styled(Link)`
max-width: 272.25px;
min-width: 221px;
@media (max-width: ${breakpoints.md}){
  width: 18rem;
}
  text-align: center;
  ${'' /* width: 70%; */}

  margin-top: 25px;
  margin-bottom: 25px;
  display: ${(props) => (props.desktop ? "none" : "block")};
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.35em 1.2em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white !important;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.6em;
  ${
    "" /* font-size: 20px;
  line-height: 18px; */
  }

  @media (max-width: ${breakpoints.md}) {
    ${"" /* margin-bottom: 20px; */}
  }
`

const InternalLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-weight: bold;
`

const ExternalLink = styled(ELink)`
  text-decoration: none;
  color: black;
  font-weight: bold;
`

const WhiteRectangle = styled.div`
  background: white;
  padding: 15px;
`
const TableDiv = styled.div`
overflow-x: scroll;
padding:15px;

`
const Table = styled.table`

text-align: center;
  border: 1px solid black;
  th,
  td {
    border: 1px solid black;
    padding: 8px;
    width: 20%;
    text-align: center;
    min-width: 150px;
  }

  th {
    font-weight: bold;
  }
`

const Th = styled.th``

const Tr = styled.tr``

const Td = styled.td`
  text-align: center;
`

const Rectangle = styled.div`
  padding: 10px;
  background-color: ${colors.lightBlue};
  height: 100%;
`
